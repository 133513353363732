import React from "react"
import Layout from "../components/layout"
import { Container } from "react-bootstrap"
import Seo from "../components/Seo/seo"
export default function Privacy() {
  return (
    <Layout>
      <Seo title="Privacy Policy" />
      <Container>
        <h1 className="my-5 text-uppercase">Privacy Policy</h1>
        <p className="lead">
          We like you to keep your privacy. We do not snoop around and do not
          collect any data that is not absolutely necessary. In more fancy words
          this means:
        </p>
        <h2>General notice and mandatory information </h2>
        <strong>Designation of the responsible entity</strong>
        <p> The responsible entity for data processing on this website is:</p>

        <p>
          <span id="s3-t-firma">Natalia Pacheco</span>
        </p>
        <p>
          <span id="s3-t-ansprechpartner"></span>
        </p>
        <p>
          <span id="s3-t-strasse">Am Husholz 6</span>
        </p>
        <p>
          <span id="s3-t-plz">50968</span>
        </p>
        <p>
          <span id="s3-t-ort">Cologne, Germany</span>
        </p>
        <p>
          The controller decides alone or jointly with others about the purposes
          and means of the processing of personal data (e.g. names, contact
          details or similar). Revocation of your consent to Data Processing
          Only with your explicit consent are some data processing operations
          are possible. A revocation of your already granted consent is possible
          at any time. For the revocation a informal communication by e-mail.
          The legality of the data processing data processing remains unaffected
          by the revocation. Right to As a data subject, you have the right to
          lodge a complaint with the competent right to lodge a complaint with
          the competent supervisory authority in the event of a data protection
          with the competent supervisory authority. Competent supervisory
          authority regarding questions of data protection law is the data
          protection commissioner of the federal state in which our company is
          based. our company is located. The following link provides a list of
          the data protection officers and their contact details:The controller
          decides alone or jointly with others about the purposes and means of
          the processing of personal data (e.g. names, contact details or
          similar). Revocation of your consent to Data Processing Only with your
          explicit consent are some data processing operations are possible. A
          revocation of your already granted consent is possible at any time.
          For the revocation a informal communication by e-mail. The legality of
          the data processing data processing remains unaffected by the
          revocation. Right to As a data subject, you have the right to lodge a
          complaint with the competent right to lodge a complaint with the
          competent supervisory authority in the event of a data protection with
          the competent supervisory authority. Competent supervisory authority
          regarding questions of data protection law is the data protection
          commissioner of the federal state in which our company is based. our
          company is located. The following link provides a list of the data
          protection officers and their contact details:
        </p>
        <p>
          <a
            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html"
            target="_blank"
            rel="noreferrer"
          >
            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html
          </a>
        </p>
        <strong>Right to data portability</strong>
        <p>
          You have the right to access data that we have processed automatically
          on the basis of your consent or in fulfillment of a contract, to
          yourself or to a third party. to third parties. The data will be
          provided in a machine-readable format. If you request the direct
          transfer of the data to another person responsible, this will only be
          done insofar as it is technically feasible. technically feasible.
          Right to information, correction, blocking, deletion You have the
          right at any time within the scope of the applicable legal the right
          to obtain information free of charge about your stored personal data
          origin of the data, its recipients and the purpose of the data
          processing and of the data processing and, if applicable, a right to
          correction, blocking or deletion of this data. In this regard and also
          for further questions on the subject of personal data, you can contact
          us at any time using the contact options listed in the imprint. SSL-
          resp. TLS encryption For security reasons and to protect the
          transmission of confidential transmission of confidential content that
          you send to us as the site operator, our our website uses SSL or TLS
          encryption. This means that data transmitted via this website cannot
          be read by third parties. You recognize an encrypted connection by the
          "https://" address line of your browser of your browser and the lock
          symbol in the browser line. Server log files In server log files, the
          provider of the website automatically collects and stores information
          that your browser automatically sends to us in server log files.
          transmitted to us. These are:
        </p>
        <ul>
          <li>Visited page on our domain</li>
          <li>Date and time of the server request</li>
          <li>Browser type and version</li>
          <li>Operating system used</li>
          <li>Referrer URL</li>
          <li>Host name of the accessing computer</li>
          <li>IP address</li>
        </ul>
        <p>
          This data is not merged with other data sources. The basis for data
          processing is Art. 6 para. 1 lit. b DSGVO, which permits the
          processing of data for the fulfillment of a contract or
          pre-contractual pre-contractual measures. Data transfer in case of
          Conclusion of contract for purchase of goods and shipment of goods
          Personal data will be transmitted to third parties only if a necessity
          exists in the context of processing of the contract. Third parties can
          be for example payment service providers or logistics companies. A
          further transmission transmission of data will not take place or only
          if you have expressly have expressly consented to this. The basis for
          data processing is Art. 6 para. 1 lit. b DSGVO, which allows the
          processing of data for the fulfillment of a contract or
          pre-contractual measures.
        </p>
        <strong>Contact form Data</strong>
        <p>
          Contact form Data transmitted via the contact form, including your
          contact data will be stored in order to process your request or to be
          available for be available for follow-up questions. A passing on of
          these data does not take place without your consent. The processing of
          the data entered in the contact form contact form is carried out
          exclusively on the basis of your consent (Art. 6 para. 1 lit. a
          DSGVO). A revocation of your already given consent is possible at any
          time. For the revocation is sufficient an informal notification by
          e-mail. The legality of the data processing operations carried out
          until the revocation data processing operations carried out until the
          revocation remains unaffected by the revocation. Via the contact form
          will remain with us until you ask us to delete the data request
          deletion, revoke your consent to storage or until there is no longer a
          need there is no longer any need to store the data. Mandatory legal
          provisions - in particular retention periods - remain unaffected.
        </p>
        <strong>Newsletter data</strong>
        <p>
          To send our newsletter, we require an e-mail address from you. A
          verification of the given e-mail address is necessary and the receipt
          of the newsletter must be to agree. Supplementary data is not
          collected or is voluntary. The data is used exclusively for sending
          the newsletter. The data provided when registering for the newsletter
          will be used processed exclusively on the basis of your consent (Art.
          6 para. 1 lit. a DSGVO). A revocation of your already granted consent
          is possible at any time. For the revocation, it is sufficient to send
          an informal message by e-mail or you unsubscribe via the "unsubscribe"
          link in the newsletter. The legality of the data processing operations
          that have already been carried out remains unaffected by the
          revocation. Data entered to set up the subscription will be deleted in
          the event of unsubscription. Should this data be used for other
          purposes and at another location, this data will remain with us.
          remain with us. We use Mailchimp for sending the newsletter, therefor
          your email adress and name you enter at the newsletter registration
          will be stored on their severs. Since you are visiting a website from
          Mailchimp you will visit their server. Here are their{" "}
          <a
            href="https://mailchimp.com/legal/privacy/"
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>{" "}
          and{" "}
          <a
            href="https://mailchimp.com/legal/cookies/"
            target="_blank"
            rel="noreferrer"
          >
            cookie statement
          </a>
          .
        </p>

        <strong>Data from Children</strong>
        <p>
          We do not collect information on the age of the participants. Should
          children be registered for the tour, we are happy to welcome them.
          However, participation in the trip is only possible if legal
          representatives agree and at least one guardian accompanies the
          underage participant.
        </p>
        <strong>Youtube</strong>
        <p>
          For integration and display of video content, our website uses plugins
          from YouTube. Provider of the video portal is YouTube, LLC, 901 Cherry
          Ave, San Bruno, CA 94066, USA. When you access a page with an
          integrated YouTube plugin, a connection to the connection to the
          YouTube servers is established. YouTube learns which of our pages you
          have accessed. YouTube can assign your your surfing behavior directly
          to your personal profile if you are logged into your be logged into
          your YouTube account. By logging out beforehand, you have the the
          possibility to prevent this. The use of YouTube is in the interest of
          an appealing presentation of our online offers. This represents a
          legitimate interest within the meaning of Art. 6 para. 1 lit. f DSGVO.
          represents. Details on the handling of user data can be found in the
          Privacy Policy of YouTube under:
        </p>
        <p>
          <a
            href="
            https://www.google.de/intl/de/policies/privacy"
            target="_blank"
            rel="noreferrer"
          >
            https://www.google.de/intl/de/policies/privacy
          </a>
        </p>

        <strong>Stripe</strong>

        <p>
          Our website uses{" "}
          <a href="https://stripe.com" target="_blank" rel="noreferrer">
            Stripe
          </a>{" "}
          for payments. Here are their{" "}
          <a href="https://stripe.com/privacy" target="_blank" rel="noreferrer">
            privacy policy
          </a>{" "}
          and{" "}
          <a
            href="https://stripe.com/en-fr/legal/cookies-policy"
            target="_blank"
            rel="noreferrer"
          >
            cookie policies
          </a>
          Additional information can be found in Strip's
          <a
            href="https://stripe.com/privacy-center/legal"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Center
          </a>
          Their address is Stripe, Inc. 354 Oyster Point Boulevard South San
          Francisco, California, 94080, USA Attention: Stripe Legal. In Europe
          it is Stripe Legal Stripe Payments Europe Limited 1 Grand Canal Street
          Lower, Grand Canal Dock, Dublin, D02 H210, Ireland Attention: Stripe
          Legal
        </p>

        <strong>PayPal</strong>

        <p>
          Our website allows payment via PayPal through stripe. The provider of
          the payment service is if you choose this option PayPal (Europe)
          S.à.r.l. et Cie, S.C.A., 22-24 Boulevard Royal, L-2449 Luxembourg. If
          you pay with PayPal payment, the payment data entered by you will be
          transmitted to PayPal. payment data to PayPal. The transmission of
          your data to PayPal takes place on the basis of Art. 6 para. 1 lit. a
          DSGVO (consent) and Art. 6 Abs. 1 lit. b DSGVO (processing for the
          fulfillment of a contract). A Revocation of your already granted
          consent is possible at any time. In past data processing operations
          remain effective in the event of a revocation. effective in the event
          of a revocation.
        </p>
        <small>
          <p>
            Source: Datenschutz-Konfigurator von{" "}
            <a
              href="http://www.mein-datenschutzbeauftragter.de"
              target="_blank"
              rel="noreferrer"
            >
              mein-datenschutzbeauftragter.de
            </a>
          </p>
          Translated with deepl.com
        </small>
      </Container>
    </Layout>
  )
}
